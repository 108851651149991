import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BannerEnum from '../../../../enums/bannerEnum';
import { setLoading } from '../../../../services/reducers/homeSlice';
import restApi from '../../../../services/restApi';
import tools from '../../../../utils/tools';
import { Sidebar } from '../../Layout/Sidebar';

const GuaranteeManage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<any>();
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [users, setUsers] = useState([]);
  const [image, setImage] = useState<any>({});
  const { id: paramId } = useParams();

  const submit = async () => {
    dispatch(setLoading(true));
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/guarantee/basic/' + (paramId || ''), true).post({
      userId: form?.userId,
      productId: form.productId,
      brandId: form.brandId,
      serial: form.serial,
    });

    if (res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: `گارانتی با موفقیت ${paramId ? 'ویرایش' : 'اضافه'} شد`,
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          // navigate('/dashboard/banner/')
        }
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await Promise.all([
      restApi(process.env.REACT_APP_BASE_URL + '/admin/brand/', true).get(),
      restApi(process.env.REACT_APP_BASE_URL + '/admin/product/', true).get(),
      restApi(process.env.REACT_APP_BASE_URL + '/admin/user/', true).get(),
      paramId && restApi(process.env.REACT_APP_BASE_URL + '/admin/guarantee/single/' + (paramId), true ).get(),
    ]);

    if (paramId) {
      setForm({
        serial: res[3].data?.serial,
        userId: res[3].data?.userId,
        brandId: res[3].data?.brandId,
        productId: res[3].data?.productId,
      })
    }

    setBrands(res[0].data)
    setProducts(res[1].data)
    setUsers(res[2].data)

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <main className="dashboardBody">
        <Sidebar/>
        <div className="dashBoardMain main">
          <div className="addInfoHeader">
            <div className="buttonContainer keepRight">
              <button className="dashboardHeader keepRight" onClick={() => submit()}>
                <p>{paramId ? 'ویرایش' : 'ایجاد'} گارانتی</p>
              </button>
            </div>
            <span>
          <h1 className="sideBarTitle">بازگشت به صفحه گارانتی ها</h1>
           <h1 className="dashBoardTitle">{paramId ? 'ویرایش' : 'ایجاد'} گارانتی</h1>
        </span>
            <i className="backAdd" onClick={() => navigate('/dashboard/banner')}></i>
          </div>
          <section className='addInfoSec'>
            <div className="AddInfoContainer">
              <label>سریال</label>
              <input className="persianName" defaultValue={form?.serial} onChange={(input) => setForm((prev) => ({...prev, serial: input.target.value}))}/>
              <label>برند</label>
              <Select className='width100' options={brands.map(e => ({label: e.title, value: e.id}))} value={{ label: brands.find(e => e.id == form?.brandId )?.title, value: form?.brandId }} onChange={(selected) => setForm(prev => ({ ...prev, brandId: selected.value}))}/>
              <label>محصول</label>
              <Select className='width100' options={products.filter(e => form?.brandId ? e.brandId == form?.brandId : true).map(e => ({label: e.title, value: e.id}))} value={{ label: products.find(e => e.id == form?.productId )?.title, value: form?.productId }} onChange={(selected) => setForm(prev => ({ ...prev, productId: selected.value, brandId: products.find(e => e.id == selected.value)?.brandId}))}/>
              <label>کاربر</label>
              <Select className='width100' options={users.map(e => ({label: e?.name + ' ' + e?.lastName, value: e.id}))} value={{ label: users.find(e => e.id == form?.userId )?.name + ' ' + users.find(e => e.id == form?.userId )?.lastName , value: form?.userId }} onChange={(selected) => setForm(prev => ({ ...prev, userId: selected.value}))}/>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default GuaranteeManage ;