const isEmpty = (obj: any) => {
  return Object.keys(obj).length === 0
}

const formatPrice = (price: number = 1, min = 0, max = 8) => {
  if (!price) return 0;
  return price.toLocaleString('en-US', {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};


const omit = (keys, obj) => {
  if (!keys.length) return obj
  const { [keys.pop()]: omitted, ...rest } = obj;
  return omit(keys, rest);
}

const getMainPic = (array) => array.length > 0 ? array?.find(e => e.code == "main") || array[0] : undefined

export default {
  isEmpty,
  omit,
  formatPrice,
  getMainPic
};