import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BannerEnum from '../../../../enums/bannerEnum';
import { setLoading } from '../../../../services/reducers/homeSlice';
import restApi from '../../../../services/restApi';
import tools from '../../../../utils/tools';
import { Sidebar } from '../../Layout/Sidebar';
import guarantee from '../index';

const GuaranteePrint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});
  const { id: paramId } = useParams()

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/guarantee/single/' + paramId, true).get()

    setData(res.data)

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main className='guaranteeFactorContainer'>
      <div className='guaranteeFactorBox'>
        <div className='guaranteeFactorHeader'>
          <span>
            {data?.brand?.title}
          </span>
          <span>
            Galata
          </span>
        </div>
        <h2>Warranty Certificate</h2>
        <h3>Camp Shopping</h3>
        <div className='guaranteeFactorCard'>
          <span>Model No:</span>
          <span>{data?.brand?.title + ' ' + data?.product?.sku}</span>
        </div>
        <div className='guaranteeFactorCard'>
          <span>Serial No:</span>
          <span>{data?.serial}</span>
        </div>
        <p>
          با تشکر از خرید شما. لطفا جهت فعالسازی گارانتی در کالای خود و اطمینان از اصالت محصول،‌کد بالا را در وبسایت زیر ثبت نمایید
        </p>
        <p>WWW.guarantee.galatagr.com</p>
      </div>
    </main>
  );
};
export default GuaranteePrint ;