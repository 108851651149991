import moment from 'jalali-moment';
import { ReactElement, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { popupSlice } from '../../../services/reducers';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import tools from '../../../utils/tools';
import { Sidebar } from '../Layout/Sidebar';
import Derham from '../Modal/Derham';

const Product = () => {
  const [data, setData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tab, setTab] = useState('all');
  const [query, setQuery] = useState('');
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 25;
  const endOffset = itemOffset + itemsPerPage;
  const filteredData = data.filter(e => e.sku.toLowerCase().includes(query.toLowerCase()) || e.title.toLowerCase().includes(query.toLowerCase())).filter(e => tab != 'all' ? e.brand?.slug == tab : true);
  let currentItems = filteredData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteItem = async (id: number) => {
    if(confirm('آیا مطمئن هستید؟')){
      dispatch(setLoading(true));

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/product/' + id, true).delete({});
      if(res.code == 204){
        Swal.fire({
          title: 'موفق',
          text: 'محصول با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }
      dispatch(setLoading(false));
      await fetchData()
    }
  }

  const list = () => {
    const rows = [];

    currentItems?.map((product: any, index) => {
      rows.push(
        <tr className="dashTr2">
          <td className="">
            <a href={`/product/${product.slug}`}><i className="eye clickable"></i></a>
            <i className="trash clickable" onClick={() => deleteItem(product.id)}></i>
            <i className="edit clickable" onClick={() => navigate('/dashboard/product/edit/' + product.id)}></i>
          </td>
          <td>sort</td>
          <td className="">{product?.sku}</td>
          <td>{product?.length > 0 ? product?.products[0]?.price : '-'}</td>
          <td className="">
            <p>{moment(product?.updatedAt).format('jYYYY/jMM/jDD')}</p>
            <p>{moment(product?.updatedAt).format('HH:ss')}</p>
          </td>
          <td className="">{product?.title}</td>
          <td>
            <img src={tools.getMainPic(product?.medias)?.url} className="width100p"/>
          </td>
          <td>{++index}</td>
        </tr>
      )
    })

    return rows;
  };

  const tabs = () => {
    const rows: ReactElement[] = [];

    [{slug: 'all', title: 'همه'}, ...brands].map(brand => rows.push(
          <span className={`ordersTag ${brand.slug == tab ? 'activeTab' : ''}`} onClick={() => setTab(brand.slug)} key={brand.slug}>
            {brand.title}
            <span className={`numberTag ${brand.slug ==  tab ? 'activeTab' : ''}`}>{data?.filter((e: any) => brand.slug != 'all' ? e.brand?.slug == brand.slug : true).length}</span>
          </span>
    ))

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await Promise.all([
      await restApi(process.env.REACT_APP_BASE_URL + '/admin/product', true).get(),
      await restApi(process.env.REACT_APP_BASE_URL + '/admin/brand', true).get(),
    ]);

    if(res[0].code == 200){
      setData(res[0].data);
    }

    if(res[1].code == 200){
      setBrands(res[1].data)
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return(
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain">
        <h1 className="dashBoardTitle">لیست محصولات</h1>
        <div className="dashTabs">
          {tabs()}
        </div>
        <div className="searchContainer">
          <div className="keepRight svgContainer">
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/product/add')}>
            <p>افزودن محصول جدید</p>
            <i className="addPlus"></i>
          </span>
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/product/pre')}>
            <p>پیش محصول ها</p>
          </span>

          </div>
          <div className="dashboardseaechBox">
            <i className="dashMagnifierIcon"></i>
            <input className="dashSearchInput" placeholder="جستجو" onChange={(input) => setQuery(input.target.value)} />
          </div>
        </div>
      <table>
        <thead>
        <tr className="dashTr1">
          <th className="">عملیات</th>
          <th>چینش</th>
          <th className="">SKU</th>
          <th>قیمت</th>
          <th className="">
            <p> آخرین ویرایش</p>
            <i className="upDown"></i>
          </th>
          <th className="">عنوان</th>
          <th>عکس</th>
          <th>ردیف</th>
        </tr>
        </thead>
        <tbody>
        {list()}
        </tbody>
      </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="بعدی >"
          onPageChange={(event) => setItemOffset((event.selected * itemsPerPage) % data.length)}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< قبلی"
          renderOnZeroPageCount={null}
          className="pagination"
          pageClassName="paginationBreak"
          previousClassName="paginationBreak"
          nextClassName="paginationBreak"
          activeClassName="paginationActive"
        />
      </main>
      </body>
    </>
  )
}
export default Product