import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './pages/App';
import { Loading } from './pages/App/loading';
import Popup from './pages/App/popup';
import Cart from './pages/Cart';
import Success from './pages/Cart/Success';
import Catalog from './pages/Catalog';
import CategorySingle from './pages/Category';
import Attribute from './pages/Dashboard/Attribute';
import AddAttribute from './pages/Dashboard/Attribute/AddAttribute';
import EditAttribute from './pages/Dashboard/Attribute/EditAttribute';
import AttributeGroup from './pages/Dashboard/AttributeGroup';
import AddAttributeGroup from './pages/Dashboard/AttributeGroup/AddAttributeGroup';
import EditAttributeGroup from './pages/Dashboard/AttributeGroup/EditAttributeGroup';
import AttributeValue from './pages/Dashboard/AttributeValue';
import AddAttributeValue from './pages/Dashboard/AttributeValue/AddAttributeValue';
import EditAttributeValue from './pages/Dashboard/AttributeValue/EditAttributeValue';
import Banner from './pages/Dashboard/Banner';
import BannerManage from './pages/Dashboard/Banner/Manage';
import Brand from './pages/Dashboard/Brand';
import AddBrand from './pages/Dashboard/Brand/AddBrand';
import EditBrand from './pages/Dashboard/Brand/EditBrand';
import Category from './pages/Dashboard/Category';
import AddCategory from './pages/Dashboard/Category/AddCategory';
import EditCategory from './pages/Dashboard/Category/EditCategory';
import DashBlog from './pages/Dashboard/DashBlog';
import AddDashBlog from './pages/Dashboard/DashBlog/AddDashBlog';
import EditDashBlog from './pages/Dashboard/DashBlog/EditDashBlog';
import Guarantee from './pages/Dashboard/Guarantee';
import GuaranteeManage from './pages/Dashboard/Guarantee/Manage';
import GuaranteePrint from './pages/Dashboard/Guarantee/Print';
import Menu from './pages/Dashboard/Menu';
import AddMenu from './pages/Dashboard/Menu/AddMenu';
import EditMenu from './pages/Dashboard/Menu/EditMenu';
import Orders from './pages/Dashboard/Order';
import Bill from './pages/Dashboard/Order/Bill';
import EditOrder from './pages/Dashboard/Order/EditOrder';
import Product from './pages/Dashboard/Product';
import AddProduct from './pages/Dashboard/Product/AddProduct';
import ProductManage from './pages/Dashboard/Product/Manage';
import PreProducts from './pages/Dashboard/Product/PreProducts';
import Tag from './pages/Dashboard/Tag';
import AddTag from './pages/Dashboard/Tag/AddTag';
import EditTag from './pages/Dashboard/Tag/EditTag';
import AddUser from './pages/Dashboard/User/AddUser';
import EditUser from './pages/Dashboard/User/EditUser';
import UsersList from './pages/Dashboard/User/UsersList';
import Home from './pages/Home';
import Login from './pages/Login';
import SingleProduct from './pages/SingleProduct';
import UserDashboard from './pages/UserDashboard';
import Factor from './pages/UserDashboard/Factor';
import OrderDetail from './pages/UserDashboard/OrderDetail';
import reportWebVitals from './reportWebVitals';
import store from './services/store';
import SingleArticle from "./pages/Blog/SingleArticle";
import Blog from "./pages/Blog";
import BrandSingle from "./pages/Brand";
import TagSingle from "./pages/Tag";
import ArticleCategory from "./pages/Dashboard/ArticleCategory";
import AddArticleCategoy from "./pages/Dashboard/ArticleCategory/AddArticleCategory";
import EditArticleCategory from "./pages/Dashboard/ArticleCategory/EditArticleCategory";

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App/>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="login" element={<Login/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/product/:slug" element={<SingleProduct/>}/>
        <Route path="/category/:slug" element={<CategorySingle/>}/>
        <Route path="/brand/:slug" element={<BrandSingle/>}/>
        <Route path="/tag/:slug" element={<TagSingle/>}/>
        <Route path="/profile">
          <Route path="" element={<UserDashboard/>}></Route>
          <Route path="order/:code" element={<OrderDetail/>}></Route>
          <Route path="order/factor/:code" element={<Factor/>}></Route>
        </Route>
       <Route path="/blog">
          <Route path="" element={<Blog/>}></Route>
          <Route path=":slug" element={<SingleArticle/>}></Route>
        </Route>
       <Route path="/catalog">
          <Route path="" element={<Catalog />}></Route>
          <Route path=":slug" element={<SingleArticle/>}></Route>
        </Route>
        <Route path="/cart" element={<Cart/>}/>
        <Route path="/order/pay/success" element={<Success/>}/>
        <Route path="dashboard">
          <Route path="tag">
            <Route path="" element={<Tag/>}/>
            <Route path="add" element={<AddTag/>}/>
            <Route path="edit/:id" element={<EditTag/>}/>
          </Route>
          <Route path="dashblog">
            <Route path="" element={<DashBlog/>}/>
            <Route path="add" element={<AddDashBlog/>}/>
            <Route path="edit/:id" element={<EditDashBlog/>}/>
          </Route>
          <Route path="banner">
            <Route path="" element={<Banner/>}/>
            <Route path="add" element={<BannerManage/>}/>
            <Route path="edit/:id" element={<BannerManage/>}/>
          </Route>
          <Route path="articleCategory">
            <Route path="" element={<ArticleCategory/>}/>
            <Route path="add" element={<AddArticleCategoy/>}/>
            <Route path="edit/:id" element={<EditArticleCategory/>}/>
          </Route>
          <Route path="bill">
            <Route path="" element={<Bill/>}/>
          </Route>
          <Route path="menu">
            <Route path="" element={<Menu/>}/>
            <Route path="add" element={<AddMenu/>}/>
            <Route path="edit/:id" element={<EditMenu/>}/>
          </Route>
          <Route path="brand">
            <Route path="" element={<Brand/>}/>
            <Route path="add" element={<AddBrand/>}/>
            <Route path="edit/:id" element={<EditBrand/>}/>
          </Route>
          <Route path="attributeGroup">
            <Route path="" element={<AttributeGroup/>}/>
            <Route path="add" element={<AddAttributeGroup/>}/>
            <Route path="edit/:id" element={<EditAttributeGroup/>}/>
          </Route>
          <Route path="attributeValue">
            <Route path="" element={<AttributeValue/>}/>
            <Route path="add" element={<AddAttributeValue/>}/>
            <Route path="edit/:id" element={<EditAttributeValue/>}/>
          </Route>
          <Route path="Category">
            <Route path="" element={<Category/>}/>
            <Route path="add" element={<AddCategory/>}/>
            <Route path="edit/:id" element={<EditCategory/>}/>
          </Route>
          <Route path="order">
            <Route path="" element={<Orders/>}/>
            <Route path="edit/:id" element={<EditOrder/>}/>
          </Route>
          <Route path="guarantee">
            <Route path="" element={<Guarantee/>}/>
            <Route path="add" element={<GuaranteeManage/>}/>
            <Route path="edit/:id" element={<GuaranteeManage/>}/>
            <Route path="print/:id" element={<GuaranteePrint/>}/>
          </Route>
          <Route path="attribute">
            <Route path="" element={<Attribute/>}/>
            <Route path="add" element={<AddAttribute/>}/>
            <Route path="edit/:id" element={<EditAttribute/>}/>
          </Route>
          <Route path="product">
            <Route path="" element={<Product/>}/>
            <Route path="add" element={<ProductManage/>}/>
            <Route path="pre" element={<PreProducts/>}/>
            <Route path="edit/:id" element={<ProductManage/>}/>
          </Route>
          <Route path="user">
            <Route path="" element={<UsersList/>}/>
            <Route path="add" element={<AddUser/>}/>
            <Route path="edit/:id" element={<EditUser/>}/>
          </Route>
        </Route>
      </Routes>
      <Popup/>
      <Loading/>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your App, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
