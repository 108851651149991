import moment from 'jalali-moment';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import tools from '../../../utils/tools';
import { Sidebar } from '../Layout/Sidebar';

const EditOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [order, setOrder] = useState<any>();
  const send = async (form) => {
    form.preventDefault();
    dispatch(setLoading(true));

    const formData = new FormData(form.target);

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/order/update/' + order.id, true).put({
      counts: formData.getAll('counts[]'),
      prices: formData.getAll('prices[]'),
      products: formData.getAll('products[]')
    })

    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'سفارش با موفقیت ویرایش شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم'
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    dispatch(setLoading(false));
  };

  const statusList = () => {
    const rows: ReactElement[] = []

    order?.orderStatuses?.map((orderProduct: any, index) => {
      rows.push(
        <tr className="" key={'product' + index}>
          <td className="backGround1">
            <p>{orderProduct.description}</p>
          </td>
          <td>{moment(orderProduct.createdAt).format('jYYYY/jMM/jDD HH:mm')}</td>
          <td>{orderProduct?.orderStatus?.title}</td>
        </tr>
      )
    })

    return rows;
  };

  const list = () => {
    const rows: ReactElement[] = []

    order?.products?.map((orderProduct: any, index) => {
      rows.push(
        <tr className="height70 gap40" key={'product' + index}>
          <td className="priceHolder backGround1">
            <p>{orderProduct.price * orderProduct.count}</p>
          </td>
          <td className="priceHolder">
            <input type="text" name={`prices[]`} className="noBorder textAlignCenter" defaultValue={orderProduct.price}/>
          </td>
          <td className="quantity">
          <div className="quantityButtom">
            <i className="tablePlusIcon" onClick={(e: any) => {
              e.target.nextElementSibling.value = Number(e.target.nextElementSibling.value) + 1;
              e.target.parentElement.parentElement.previousElementSibling.previousElementSibling.children[0].innerHTML = Number(e.target.nextElementSibling.value) * Number(e.target.parentElement.parentElement.previousElementSibling.children[0].value)
            }}></i>
            <input className="quantityNumber" defaultValue={orderProduct.count} name={`counts[]`} onChange={(e: any) => e.target.parentElement.parentElement.previousElementSibling.previousElementSibling.children[0].innerHTML = Number(e.target.value) * Number(e.target.parentElement.parentElement.nextElementSibling.children[0].value)}/>
            <i className="tableCollapsIcon" onClick={(e: any) => {
              e.target.previousElementSibling.value = Number(e.target.previousElementSibling.value) - 1;
              e.target.parentElement.parentElement.previousElementSibling.previousElementSibling.children[0].innerHTML = Number(e.target.previousElementSibling.value) * Number(e.target.parentElement.parentElement.previousElementSibling.children[0].value)
            }}></i>
          </div>
        </td>
          <td className="skuContainer textAlignCenter">{orderProduct.product.sku}</td>
          <td className="nameContainer">
            <p className="font12 textAlignRight">{orderProduct.product.title}</p>
            <p>{orderProduct.product.category.title}</p>
          </td>
          <td><img className="width100p" src={tools.getMainPic(orderProduct.product.medias)?.url}/></td>
          <td>
            <i className="cancelSvg"></i>
            <input type="hidden" name="products[]" value={orderProduct.productId}/></td>
        </tr>
      )
    })

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/order/' + id, true).get();

    setOrder(res.data)

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return(
    <>
      <body className="dashboardBody">
      <Sidebar/>
      <form onSubmit={send} className="form">

      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <button className="dashboardHeader keepRight" type="submit">
            <p>ویرایش سفارش</p>
          </button>
          <span>
            <h1 className="sideBarTitle"> بازگشت به صفحه لیست سفارش ها</h1>
             <h1 className="dashBoardTitle">ویرایش سفارش</h1>
          </span>
          <i className="backAdd"></i>
        </div>
        <section className="topRow">
          <div className="infoSection">
          <h1 className="dashBoardTitle">اطلاعات کاربر</h1>
          <div className="userInfoContainer">
          <label className="sideBarTitle">نام و خانوادگی</label>
            <input className="editProductInput" value={order?.user?.name}/>
            <label className="sideBarTitle">شماره تلفن</label>
            <input className="editProductInput" value={order?.user?.phoneNumber}/>
            <label className="sideBarTitle" >ایمیل</label>
            <input className="editProductInput" value={order?.user?.email}/>
          </div>
        </div>
          <div className="infoSection">
            <h1 className="dashBoardTitle">آدرس</h1>
            <div className="userInfoContainer">
              <label className="sideBarTitle">ارسال به</label>
              <select className="selector width">
                <option>آدرس ۱</option>
              </select>
             <h6 className="sideBarTitle">جزئیات آدرس ۱</h6>
              <p className="catAdresses">
                {order?.address?.province?.title + ' - ' + order?.address?.city?.title + ' - ' + order?.address?.text}
              <br/>
                {order?.address?.postalCode}
              </p>
            </div>
          </div>
          <div className="infoSection">
            <h1 className="dashBoardTitle">مجموع فاکتور</h1>
            <div className="userInfoContainer">
             <span className="factorHeader">
               <p>{moment(order?.createdAt).format('jYYYY/jMM/jDD')}</p>
             </span>
              <span className="billItems dashboardBill">
              <h3 className="billItem">هزینه ارسال</h3>
              <div className="pricePart">
                <h1 className="billPrice">{tools.formatPrice(order?.delivery?.price)}</h1>
              </div>
            </span>
              <span className="billItems dashboardBill">
              <h3 className="billItem">تخفیف</h3>
              <div className="pricePart">
                <h1 className="billPrice">{tools.formatPrice(order?.discountPrice)}</h1>
              </div>
            </span>
              <span className="billItems dashboardBill">
              <h3 className="billItem">مالیات</h3>
              <div className="pricePart">
                <h1 className="billPrice">3%</h1>
              </div>
            </span>
              <hr className="dashedBill"/>
              <span className="billItems dashboardBill">
              <h3 className="billItem">مبلغ قابل پرداخت</h3>
              <div className="pricePart">
                <h1 className="tablePrice1">DHs{tools.formatPrice(order?.price)}</h1>
              </div>
            </span>
            </div>
          </div>
        </section>
        <section className="bottom">
          <h6 className="dashBoardTitle">وضعیت ها</h6>
          <table className="productTable">
            <thead className="editOrderTable">
            <th className="sideBarTitle center" >توضیحات</th>
            <th className="sideBarTitle center" >تاریخ</th>
            <th className="sideBarTitle center" >وضعیت</th>
            </thead>
            <tbody>
            {statusList()}
            </tbody>
            {/* <tr className="addProductTr"> */}
            {/*   <td className="addProductButton">اضافه کردن محصول</td> */}
            {/* </tr> */}
          </table>
        </section>
        <section className="bottom">
        <h6 className="dashBoardTitle">محصولات</h6>
          <table className="productTable">
          <thead className="editOrderTable">
            <th className="sideBarTitle center" >قیمت کل</th>
            <th className="sideBarTitle center" >قیمت واحد</th>
            <th className="sideBarTitle center" >تعداد</th>
            <th className="sideBarTitle center" >SKU</th>
            <th className="sideBarTitle center" >محصول</th>
            <th className="sideBarTitle center"></th>
          </thead>
            <tbody>
            {list()}
            </tbody>
            {/* <tr className="addProductTr"> */}
            {/*   <td className="addProductButton">اضافه کردن محصول</td> */}
            {/* </tr> */}
          </table>
        </section>
      </main>
        </form>
      </body>
    </>
  )
}
export default EditOrder