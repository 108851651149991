import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { setLoading } from '../../../../services/reducers/homeSlice';
import restApi from '../../../../services/restApi';
import { Sidebar } from '../../Layout/Sidebar';

const ProductManage = () => {
  const dispatch = useDispatch();
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const [images, setImages] = useState<any[]>([]);
  const [brands, setBrands] = useState([]);
  const [attributeGroups, setAttributeGroups] = useState([]);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [attributeGroup, setAttributeGroup] = useState<any>();
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [form, setForm] = useState<any>();

  const send = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/product/basic/' + (paramId || ''), true).post(form);
    let formData = new FormData();

    images?.map((image: any, index) => {
      formData.append(`files[${image.id}]`, (image.data || {}));
    });

    images.filter(e => e.id?.includes('d-')).map(e => formData.append('deleted[]', e.id.split('-')[1]));
    const results = await Promise.all([
      restApi(process.env.REACT_APP_BASE_URL + '/admin/product/attributes/' + res.data?.id, true).post({ attributes: selectedAttributes }),
      restApi(process.env.REACT_APP_BASE_URL + '/admin/product/medias/' + res.data?.id, true).upload(formData),
    ]);

    if (res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: `محصول با موفقیت ${paramId ? 'ویرایش' : 'ایجاد'} شد`,
        icon: 'success',
        confirmButtonText: 'متوجه شدم'
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };

  const list = () => {
    const rows: ReactElement[] = [];
    attributeGroup?.attributes?.filter(e => !e.isTechnology)?.map((attribute: any, index) => {
      rows.push(
        <div className="inputAttribute" key={`modal` + index}>
          <label className="sideBarTitle">{attribute.title}</label>
          <select className="modalSelector" value={selectedAttributes[attribute.slug]} onChange={(input) => setSelectedAttributes(prev => {
            const cp = { ...prev };

            cp[attribute.slug] = input.target.value;

            return cp;
          })}>
            {attribute.attributeValues.map((attributeValue: any) =>
              <option value={attributeValue.slug}>{attributeValue.title}</option>
            )}
          </select>
        </div>
      );
    });

    return rows;
  };

  const handleFileChange = (e) => {
    const files = [];

    for (let i = 0; i < e.target.files.length; i++) {
      files.push({
        preview: URL.createObjectURL(e.target.files[i]),
        data: e.target.files[i],
        id: 'n-' + (images.length + i)
      });
    }

    setImages([...images, ...files]);
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    await Promise.all([
      restApi(process.env.REACT_APP_BASE_URL + '/admin/attributeGroup', true).get(),
      restApi(process.env.REACT_APP_BASE_URL + '/admin/brand', true).get(),
      restApi(process.env.REACT_APP_BASE_URL + '/admin/tag', true).get(),
      restApi(process.env.REACT_APP_BASE_URL + '/admin/category', true).get(),
      paramId && restApi(process.env.REACT_APP_BASE_URL + '/admin/product/single/' + paramId, true).get()
    ]).then(async (result) => {

      setAttributeGroups(result[0].data);
      setBrands(result[1].data);
      setTags(result[2].data);
      setCategories(result[3].data);

      if (paramId) {
        setForm({
          title: result[4].data.title,
          titleEng: result[4].data.titleEng,
          shortText: result[4].data.shortText,
          longText: result[4].data.longText,
          categoryId: result[4].data.categoryId,
          attributeGroupId: result[4].data.attributeGroupId,
          tagId: result[4].data.tagId,
          model: result[4].data.model,
          brandId: result[4].data.brandId,
          status: result[4].data.status,
          sku: result[4].data.sku,
          count: result[4].data.count,
          price: result[4].data.price,
          wholesomePrice: result[4].data.wholesomePrice,
          discountPrice: result[4].data.discountPrice,
          sort: result[4].data.sort,
          slug: result[4].data.slug,
        });
        setImages(result[4].data?.medias?.map((e, index) => ({
          preview: e.url,
          id: e.id?.toString()
        })));
        const attributes = {};

        result[4].data.attributeProducts?.filter(e => !e.attribute?.isTechnology).map(e => attributes[e.attributeSlug] = e.attributeValueSlug);

        setSelectedAttributes(attributes);
      }
    });
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (form?.attributeGroupId) {
      setAttributeGroup(attributeGroups?.find(e => e.id == (form.attributeGroupId)));
    }
  }, [form?.attributeGroupId]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <body className="dashboardBody">
      <Sidebar/>
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <div className="buttonContainer keepRight">
            <button className="dashboardHeader keepRight" onClick={() => send()}>
              <p>{paramId ? 'ویرایش' : 'ایجاد'} محصول</p>
            </button>
            {paramId &&
                <span className="saveButton clickable" onClick={() => navigate(`/product/${form.slug}`)}>
              <p>مشاهده محصول</p>
            </span>
            }

          </div>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه محصولات</h1>
             <h1 className="dashBoardTitle">{paramId ? 'ویرایش' : 'ایجاد'} محصول</h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate('/dashboard/product')}></i>
        </div>
        <span className="titles">
          <h6 className="marginRight">توضیحات</h6>
            <h6>اطلاعات محصول</h6>
            </span>
        <section className="addProductTop">
          <div className="AddInfoContainer">
            <label>توضیح کوتاه</label>
            <textarea className="ShortDiscription" name="shortText" value={form?.shortText} onChange={(input) => setForm(prev => ({
              ...prev,
              shortText: input.target.value
            }))}/>
            <label>توضیح بلند</label>
            <textarea className="ShortDiscription" name="longText" value={form?.longText} onChange={(input) => setForm(prev => ({
              ...prev,
              longText: input.target.value
            }))}/>
          </div>
          <div className="AddInfoContainer">
            <label>نام فارسی</label>
            <input className="persianName" name="title" value={form?.title} onChange={(input) => setForm(prev => ({
              ...prev,
              title: input.target.value
            }))}/>
            <div className="input2">
              <div className="input3 width100">
                <label> چینش</label>
                <input className="halfInput" name="sort" value={form?.sort} onChange={(input) => setForm(prev => ({
                  ...prev,
                  sort: input.target.value
                }))}/>
              </div>
              <div className="input3 width100">
                <label>نام انگلیسی</label>
                <input className="halfInput" name="titleEng" value={form?.titleEng} onChange={(input) => setForm(prev => ({
                  ...prev,
                  titleEng: input.target.value
                }))}/>
              </div>
            </div>
            <div className="input2">
              <div className="input3 width100">
                <label> sku</label>
                <input className="halfInput" name="sort" value={form?.sku} onChange={(input) => setForm(prev => ({
                  ...prev,
                  sku: input.target.value
                }))}/>
              </div>
              <div className="input3 width100">
                <label>مدل</label>
                <input className="halfInput" name="titleEng" value={form?.model} onChange={(input) => setForm(prev => ({
                  ...prev,
                  model: input.target.value
                }))}/>
              </div>
            </div>
            <div className="input2">
              <div className="input3 width100">
                <label>دسته بندی مشخصات فنی</label>
                <select className="selector" name="attributeGroupId" value={form?.attributeGroupId} onChange={(input) => setForm(prev => ({
                  ...prev,
                  attributeGroupId: input.target.value
                }))}>
                  <option value={null}>انتخاب کنید</option>
                  {attributeGroups?.map((attributeGroup: any, index) => <option value={attributeGroup.id}>{attributeGroup.title}</option>)}
                </select>
              </div>
              <div className="input3 width100">
                <label>دسته بندی</label>
                <select className="selector" name="categoryId" value={form?.categoryId} onChange={(input) => setForm(prev => ({
                  ...prev,
                  categoryId: input.target.value
                }))}>
                  <option value={null}>انتخاب کنید</option>
                  {categories?.map((category: any, index) => <option value={category.id}>{category.title}</option>)}
                </select>
              </div>
              <div className="input3 width100">
                <label>برند</label>
                <select className="selector" name="brandId" value={form?.brandId} onChange={(input) => setForm(prev => ({
                  ...prev,
                  brandId: input.target.value
                }))}>
                  <option value={null}>انتخاب کنید</option>
                  {brands?.map((brand: any) => <option value={brand.id}>{brand.title}</option>)}
                </select>
              </div>
            </div>
            <div className="input2">
              <div className="input3 width100">
                <label>تگ</label>
                <select className="selector" name="tagId" defaultValue={form?.tagId} onChange={(input) => setForm(prev => ({
                  ...prev,
                  tagId: input.target.value
                }))}>
                  <option value={null}>انتخاب کنید</option>
                  {tags?.map((tag: any, index) => <option key={index} value={tag.id}>{tag.title}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="AddInfoContainer">
            <div className="input3 width100">
              <label>وضعیت</label>
              <select className="selector" name="groupStatus" value={form?.status} onChange={(input) => setForm(prev => ({
                ...prev,
                status: input.target.value
              }))}>
                <option value={1}>فعال</option>
                <option value={0}>غیر فعال</option>
              </select>
            </div>
            <div className="input2">
              <div className="input3 width100">
                <label>قیمت</label>
                <input className="halfInput" value={form?.price} onChange={(input) => setForm(prev => ({
                  ...prev,
                  price: input.target.value
                }))}/>
              </div>
            </div>
            <div className="input2">
              <div className="input3 width100">
                <label>تعداد</label>
                <input className="halfInput" value={form?.count} onChange={(input) => setForm(prev => ({
                  ...prev,
                  count: input.target.value
                }))}/>
              </div>
            </div>
            <div className="input2">
              <div className="input3 width100">
                <label>قیمت همکار</label>
                <input className="halfInput" value={form?.wholesomePrice} onChange={(input) => setForm(prev => ({
                  ...prev,
                  wholesomePrice: input.target.value
                }))}/>
              </div>
            </div>
            <div className="input2">
              <div className="input3 width100">
                <label>قیمت با تخفیف</label>
                <input className="halfInput" value={form?.discountPrice} onChange={(input) => setForm(prev => ({
                  ...prev,
                  discountPrice: input.target.value
                }))}/>
              </div>
            </div>
          </div>
          <div className="otherPhotos">
            <div className="mainPhotoHeader">
              <input className="hidden" multiple={true} type="file" title="سایر عکس ها" id="uploadMainPic" onChange={handleFileChange}/>
              <label className="dashboardHeader keepRight clickable" htmlFor="uploadMainPic">
                <p>بارگزاری عکس</p>
                <i className="uploadSvg"></i>
              </label>
              <span className="mainPhotoTitle">
                <p>گالری</p>
                <p className="sideBarTitle">حداکثر ۱۶ عکس میتوانید در این قسمت بارگزاری کنید</p>
              </span>
            </div>
            <div className="otherPhotosContainer">
              {images?.filter(e => !e.id?.includes('d'))?.map((image) =>
                <span>
                    <img src={image.preview} id={image.id} className="galleryImages"/>
                    <i className="delPicOther" id={image.id} onClick={() => setImages(prev => {
                      const cp = [...prev];
                      const index = prev.findIndex(e => e?.id == image.id);
                      if (cp[index].id.includes('n')) {
                        cp.splice(index, 1);
                      } else {
                        cp[index].id = 'd-' + cp[prev.findIndex(e => e.id == image.id)].id;
                      }
                      return cp;
                    })}></i>
                  </span>
              )}
            </div>
          </div>
          <div className="mainAttributeModal">
            <h5 className="titles">مشخصات فنی</h5>
            <div className="modalAttributeGroup">
              {list()}
            </div>
          </div>
        </section>
        {/* <section className="addProductTop"> */}
        {/*    */}
        {/*   <div className="otherPhotos"> */}
        {/*     <div className="mainPhotoHeader"> */}
        {/*       <input className="hidden" type="file" title="ویدیو" id="uploadMainVid" onChange={handleFileChange}/> */}
        {/*       <label className="dashboardHeader keepRight clickable" htmlFor="uploadMainVid"> */}
        {/*         <p>بارگزاری ویدیو</p> */}
        {/*         <i className="uploadSvg"></i> */}
        {/*       </label> */}
        {/*       <span className="mainPhotoTitle"> */}
        {/*       <p>ویدیو</p> */}
        {/*         /!*<p className="sideBarTitle">حداکثر ۱۶ عکس میتوانید در این قسمت بارگزاری کنید</p>*!/ */}
        {/*     </span> */}
        {/*     </div> */}
        {/*     /!* <div className="otherPhotosContainer"> *!/ */}
        {/*     /!*   {images?.map((image) => *!/ */}
        {/*     /!*       <span> *!/ */}
        {/*     /!*   <img src={image.preview} className="galleryImages"/> *!/ */}
        {/*     /!*   <i className="delPicOther"></i> *!/ */}
        {/*     /!* </span> *!/ */}
        {/*     /!*   )} *!/ */}
        {/*     /!* </div> *!/ */}
        {/*   </div> */}
        {/* </section> */}
        {/* <button className="addProductRow" */}
        {/*         onClick={() => setEachProductRows([...eachProductRows, <EachProduct attributeGroup={attributeGroup} setDeleteItem={setDeleteItem} images={images} index={eachProductRows.length}/>])}>افزدون محصول + */}
        {/* </button> */}
      </main>
      </body>
    </>
  );
};
export default ProductManage;