import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import useTicker from '../../hooks/useTicker';
import Header from '../../layouts/Header';
import ProductCard from '../../layouts/ProductCard';
import { setLoading } from '../../services/reducers/homeSlice';
import restApi from '../../services/restApi';
import tools from '../../utils/tools';
import { MenuRow } from './MenuRow';

const TagSingle = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [tag, setTag] = useState<any>();
  const [attributes, setAttributes] = useState<any>([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [onlyAvailable, setOnlyAvailable] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [chosen, setChosen] = useState([]);
  const itemsPerPage = 24;
  const endOffset = itemOffset + itemsPerPage;
  let currentItems = tag?.products?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(tag?.products?.length / itemsPerPage)
  const { calcPrice } = useTicker();

  const attributeList = () => {
    const rows = [];

    attributes.filter(e => !e.isTechnology).map((attribute, index) => {
      rows.push(<MenuRow key={'attribute' + index} chosen={chosen} attribute={attribute} setChosen={setChosen}/>);
    });

    return rows;
  };

  const list = () => {
    const rows = [];

    currentItems?.sort((a, b) => a.title.localeCompare(b.title))?.map((product, index) => {
      rows.push(
        <ProductCard product={product} key={product.slug} />
      );
    });

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/attribute/').get();

    if (res.code == 200) {
      setAttributes(res.data);
    }

    dispatch(setLoading(false));
  };

  const fetchItem = async () => {
    dispatch(setLoading(true));
    let urlParams = '?'
    let index = 0
    for(let entry of searchParam.entries()) {
      ++index;
      urlParams += ('attribute[]=' + entry[1] + (index != (searchParam as any).size ? '&' : ''));
    }
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/tag/' + slug + urlParams).get();

    if (res.code == 200) {
      setTag(res.data);
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchItem();

    fetchData();
  }, []);

  useEffect(() => {
    fetchItem();
  }, [searchParam]);

  return (
    <>
      <Header/>
      <div className="categoryBanner"></div>
      <main className="categoryPage">
        <section className="tagLeft">
          <div className="cardHeader">
            <h1 className="categoryBreadCrumb2">{tag?.title}</h1>
          </div>
          <div className="categoryCardContainer">
            {list()}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="بعدی >"
            onPageChange={(event) => setItemOffset((event.selected * itemsPerPage) % tag?.products?.length)}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< قبلی"
            renderOnZeroPageCount={null}
            className="pagination"
            pageClassName="paginationBreak"
            previousClassName="paginationBreak"
            nextClassName="paginationBreak"
            activeClassName="paginationActive"
          />
        </section>
        <aside>
          <ul className="catFilterContainer">
            <li className="catLi">
              <div className="catRow">
                <p>فقط نمایش کالاهای موجود</p>
                <input type="checkbox" checked={onlyAvailable} onClick={() => setOnlyAvailable(!onlyAvailable)}/>
              </div>
            </li>
            {attributeList()}
          </ul>
        </aside>
      </main>
    </>
  );
};
export default TagSingle;