import { useNavigate } from 'react-router-dom';
import { popupSlice } from "../../../services/reducers";
import { useDispatch } from "react-redux";
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import tools from '../../../utils/tools';

const OrderDetail = ({ order }: any) =>{
  const dispatch: any = useDispatch();
  const navigate = useNavigate()
  const guaranteeFactorGenerator = async (id) => {
    if (confirm('برای این محصول گارانتی ایجاد شود؟')){
      dispatch(setLoading(true))

      const product = order.products.find(e => e.id == id)

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/guarantee/basic/', true).post({
        productId: product?.product?.id,
        brandId: product.product?.brandId,
        userId: order.userId,
        serial: '123',
        orderId: order.id
      })

      if (res.code == 200){
        dispatch(popupSlice.hide())
        navigate('/dashboard/guarantee/print/' + res.data?.id);
      }

      dispatch(setLoading(false))
    }
  };

  const list = () => {
    return order.products?.map((orderProduct, index) =>
      <tr key={orderProduct.id}>
        <td>
          <i className='factorSvg' onClick={async () => await guaranteeFactorGenerator(orderProduct.id)}></i>
        </td>
        <td>{tools.formatPrice(orderProduct.price)}</td>
        <td>{orderProduct.count}</td>
        <td>{orderProduct.product.title}</td>
        <td><img src={orderProduct.product?.media?.url} alt={orderProduct.product.title}/></td>
        <td>{++index}</td>
      </tr>
    )
  }
  return(
    <main className="billDetailContainer">
      <table>
        <thead>
        <tr>
          <th>عملیات</th>
          <th>قیمت</th>
          <th>تعداد</th>
          <th>محصول</th>
          <th>عکس</th>
          <th>ردیف</th>
        </tr>
        </thead>
        <tbody>
        {list()}
        </tbody>
      </table>
    </main>
  )
}
export default OrderDetail